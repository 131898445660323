.img{
    padding: 1rem;
}
.ctimg {
    padding: 1rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.linehead {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.hide {
    display: none;
}