.overlay {
    height: 100%;
    width: 100%;
    display: none;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    animation: fadeIn linear 0.3s;
}
.sidebarmobile {
    transform: translateX(-100%);
    transition: transform 0.5s;
}
.sidebarmobile.open {
    transform: translateX(0%)
}
.overlay.open {
    display: block;
}
.content {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.sidebar {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: -12px !important;
}
@media (max-width: 767.98px) {
    .logo {
        display: none;
    }
    .name {
        display: none;
    }

    .headerAd {
        justify-content: space-between;
    }

    .btnList {
        display: block;
        padding: 24px;
    }

    .adminAva .avatar {
        margin-right: 0;
    }
    .sidebar {
        display: none;
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}