.layout {
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
}
.rankTable {
    padding: 20px;
    width: 50%;
    border-radius: 10px;
}
.rankTable h2{
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
    border: 1px dashed black;
    background-color: greenyellow;
    width: 100%;
}

.font{
    font-weight: bold;
}
@media (max-width: 767.98px) {
    .layout {
        height: 92vh;
        align-items: flex-start;
    }
    .rankTable {
        width: auto;
    }
}