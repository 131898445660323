
.left, .right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.content {
    padding-top: 30px;
    padding-bottom: 30px;
}
.reverse {
    flex-direction: row-reverse;
}
.slogan {
    text-align: center;
}
.slogan h1{
    font-weight: bold;
}
@media (max-width: 767.98px) {
    .left {
        margin: 30px 0;
    }
}