.sidebar{
    padding-top: 4vh;
    position: relative;
    height: 88.5vh;
    background-color: aliceblue;
}
.option button{
    display: flex;
    justify-content: left;
    color: #000;
    width: 100%;
    height: 5vh;
    text-transform: none !important;
    font-size: inherit;
    margin-bottom: 7px;
}
.option button svg{
    margin-left: 15px;
    margin-right: 3px;
    font-size: 35px !important;
}
.option{
    padding-left: 10px;
}

.text{
    text-decoration: none;
    color: #000;
}

.sidebar .logOut{
    position: absolute;
    bottom: 16px;
}