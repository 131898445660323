.card{
    margin-bottom: 5vh;
}
.content{
    margin-top: 5vh;
    margin-right: -12px;
}
.cardHead{
    font-weight: bold;
}
@media (max-width: 767.98px) {
    .content {
        margin-right: 0;
    }
}