.background {
    background-color: #e1e1e1;
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
}
.back {
    position: absolute;
    top : 0;
    left: 0;
    margin-top: 10px;
    margin-left: 16px;
}
.card{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}
.progress {
    display: flex;
    justify-content: space-between;
}
.btn{
    width: 100px;
}
.cardBody {
    text-align: center;

}

.actionBtn{
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
}


.cardBody .iconBtn{
    font-size: 100px;
    background-color: aliceblue;
}

.content{
    display: flex;
    justify-content: center;
}
@media (max-width: 767.98px) {
    .card {
        width: 80%;
    }
}