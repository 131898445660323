.filterbox {
    -webkit-box-shadow: 0px 4px 5px 0px rgba(156,156,156,1);
    -moz-box-shadow: 0px 4px 5px 0px rgba(156,156,156,1);
    box-shadow: 0px 4px 5px 0px rgba(156,156,156,1);
    padding: 12px;
    margin-bottom: 20px;

}
.addBtn {
    display: flex;
    justify-content: flex-end;
}
.content {
    margin-right: -12px;
    position: relative;
    height: 88vh;
}
.btnmobile {
    position: absolute;
    display: none;
    bottom: 0;
    right: 0;
    margin-bottom: 10px;
    margin-right: 12px;
    z-index: 10;
}
@media (max-width: 767.98px) {
    .content {
        margin-right: 0;
        height: 90vh;
    }
    .btnWeb {
        display: none;
    }
    .btnmobile {
        display: block;
    }
}