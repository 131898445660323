.left img{
    height: 100vh;
    padding-left: 5rem;
}

.left{
    background-color: gainsboro;
}
.right {
    margin-top: 20px;
    min-height: 60vh;
}
.welcome{
   display: flex;
   justify-content: center;
   margin: 2rem;
}
.line {
    width: 32.5%;
    margin-left: 7px;
    margin-right: 7px;
}
.or {
    display: flex;
    justify-content: center;
    align-items: center;
}
.box {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px;
}
.box div {
    margin: 10px;
}
.btnlogin{
    display: flex;
    justify-content: center;
}
@media (max-width: 767.98px) {
    .left {
        display: none
    }
    .line {
        width: 42%;
    }
}