.logo img {
    padding: 1rem;
}
.logo {
    margin-bottom: 3vh;
}
.sidebar{
    position: fixed;
    z-index: 20;
    height: 100vh;
    background-color: aliceblue;
    width: 60%;
    transform: translateX(-100%);
    opacity: 0;
    transition: transform linear 0.3s, opacity linear 0.3s
}
.sidebar.open {
    transform : translateX(0%);
    opacity: 1;
}
.option button{
    display: flex;
    justify-content: left;
    color: #000;
    width: 100%;
    height: 5vh;
    text-transform: none !important;
    font-size: inherit;
    margin-bottom: 7px;
}
.option button svg{
    margin-left: 15px;
    margin-right: 3px;
    font-size: 35px !important;
}

.text{
    text-decoration: none;
    color: #000;
}

.sidebar .logOut{
    position: absolute;
    bottom: 10px;
}

