.logo {
    margin-left: 16px;
}
.logo img{
    padding: 1rem;
}
.headerAd {
    padding: 0;
    height: 5rem;
    background-color: lightskyblue;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.btnList {
    display: none;
}
.adminAva {
    display: flex;
    font-size: x-large;
    padding-right: 24px;
}

.adminAva .avatar {
    margin-right: 2vh;
}
@media (max-width: 767.98px) {
    .logo {
        display: none;
    }
    .name {
        display: none;
    }

    .headerAd {
        justify-content: space-between;
    }

    .btnList {
        display: block;
        padding: 24px;
    }

    .adminAva .avatar {
        margin-right: 0;
    }
}