.image {
    width: 240px;
    height: inherit;
    padding: 24px;
}
.leftside {
    text-align: end;
}
@media (max-width: 767.98px) {
    .leftside {
        text-align: start;
    }
}