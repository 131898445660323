.logo img{  
    padding: 1rem;
}
.logo{
    margin-bottom: 3vh;
}
.sidebar{
    position: relative;
    height: 100vh;
    background-color: aliceblue;
}
.fixed {
    position: fixed;
    width: 16.666666667%
}
.option button{
    display: flex;
    justify-content: left;
    color: #000;
    width: 100%;
    height: 5vh;
    text-transform: none !important;
    font-size: inherit;
    margin-bottom: 7px;
}
.option button svg{
    margin-left: 15px;
    margin-right: 3px;
    font-size: 35px !important;
}

.text{
    text-decoration: none;
    color: #000;
}

.sidebar .logOut{
    position: absolute;
    bottom: 10px;
}
@media (max-width: 767.98px) {
    .sidebar {
        display: none;
    }
}
