.layout {
    height: 100vh;
}
.option .btn {
    margin-top: 3rem;
    width: 10rem;
    background-color: black;
}

.option {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5rem;
    margin-top: 3rem;
    width: 50%;
}

.cardheader {
    font-weight: bold;
}

.word {
    background-image: url('../../../images/word.png');
}

@media (max-width: 767.98px) {
    .option {
        width: 90%;
    }
}