.sidebar {
    padding: 0;
}
.overlay {
    height: 100%;
    width: 100%;
    display: none;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    animation: fadeIn linear 0.3s;
}
.btnList {
    display: none;
}
.sidebarmobile {
    transform: translateX(-100%);
    transition: transform 0.5s;
}
.sidebarmobile.open {
    transform: translateX(0%)
}
.overlay.open {
    display: block;
}
.headerUs {
    padding: 0;
    height: 5rem;
    background-color: #c2d8e0;
    display: none;
}
.adminAva {
    display: flex;
    font-size: x-large;
    padding-right: 24px;
}
.adminAva .avatar {
    margin-right: 2vh;
}
.content{
    padding-left: 0 !important;
    padding-right: 0 !important;
    background-color: #c4edf2;
}
@media (max-width: 767.98px) {
    .name {
        display: none;
    }
    .headerUs {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .btnList {
        display: block;
        padding: 24px;

    }

    .adminAva .avatar {
        margin-right: 0;
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}