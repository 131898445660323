.layout {
    padding: 10px;
    background-color: #e1e1e1;
    height: 100vh;
}
.card{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
    width: 50%;
}
@media (max-width: 767.98px) {
    .card {
        width: 80%;
    }
}