.layout {
    height: fit-content;
}
.centerBlock{
    display: block;
    margin: auto;
    margin-top: 10vh;
    width: 50%;
    border: 1px solid black;
    border-radius: 10px;
    padding: 5vh;
    background-color: aliceblue;
}
.centerBlock h2 {
    text-align: center;
}

.dropzone {
    border: 2px dashed #ddd;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
}
  
.avatar_preview {
    margin-top: 20px;
}
  
.avatar_image {
    object-fit: cover;
    border-radius: 50%;
}

.block{
    border: 1px dashed black;
    margin-bottom: 2vh;
    border-radius: 10px;
    padding: 2vh;
}

.color{
    color: green;
}
@media (max-width: 767.98px) {
    .layout {
        height: 82vh;
    }
    .centerBlock {
        width: 90%;
        padding: 20px;
    }
    .block h4{
        font-size: 18px;
    }
}