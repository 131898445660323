.layout {
    height: fit-content;
}
.card{
    display: block;
    margin: 3rem auto;
    width: 50%;
}
.back{
    margin: 10px;
}
.headerbox {
    display: flex;
    justify-content: space-between;
}
.progressbar {
    margin-bottom: 20px;
}
@media (max-width: 767.98px) {
    .layout {
        height: fit-content;
    }
    .card {
        width: 90%
    }
}